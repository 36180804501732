import { Bar, type BarOptions } from '@antv/g2plot';
import { useDeepCompareEffect } from 'ahooks';
import { memo, useRef } from 'react';
import { useGlobalMyInfo } from '../myInfo/useGlobalMyInfo';

interface propType extends BarOptions {
    height: number;
}

export const BarComponent = memo((props: propType) => {
    const container = useRef(null);
    const ins = useRef<Bar | null>(null);

    const [stateParams] = useGlobalMyInfo();
    const isShowLabel =
        stateParams?.globalSensitiveInformationHiding === false ?? true;

    const shouldData = props.data.map((a) => {
        a.value = a.value?.toNumber ? a.value.toNumber() : a.value;

        return a;
    });

    useDeepCompareEffect(() => {
        if (!shouldData) {
            return;
        }

        if (ins.current) {
            ins.current?.changeData(shouldData);

            // ins.current.render();
            return;
        }

        if (!container.current) {
            return;
        }

        const l = new Bar(container.current, {
            ...props,

            data: props.data.map((a) => {
                a.value = a.value?.toNumber ? a.value.toNumber() : a.value;

                return a;
            }),
            label: {
                // 可手动配置 label 数据标签位置
                position: 'middle', // 'top', 'bottom', 'middle',
                // 配置样式
                style: {
                    fill: '#000000',
                    opacity: isShowLabel ? 1 : 0,
                },
            },
            legend: {
                position: 'top-left',
            },
            annotations: isShowLabel ? props?.annotations : undefined
        });

        ins.current = l;
        l.render();
    }, [props.data]);


    return (
        <div
            style={{
                height: props.height,
            }}
            ref={container}
        />
    );
});
