import { Card, Form, Table } from 'antd';
import { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import StockTable from './component/StockTable';
import { MonthTimePicker } from '../apartment/overview/MonthTimePicker';
import { useQuery } from '@tanstack/react-query';
import { endOfMonth, startOfMonth } from '@/utils/timeUtil';
import dayjs from 'dayjs';
import { honoClient } from './honoClient';
import { globalAntdPagination } from '../apartment/util/globalAntdPagination';
import { LineComponent } from '../apartment/flow/Line';
import { groupBy, isString } from 'lodash-es';
import { roundFix4 } from './TransactionEdit';
import { roundFix2 } from './roundFix2';
import { ColumnComponent } from '../apartment/flow/Column';
import { PlotContainer } from '../apartment/flow/PlotContainer';
import { useTitle } from 'ahooks';
import { useOverviewState } from '../apartment/overview/useOverviewState';
import { useGlobalManagementStore } from '../apartment/myInfo/useGlobalManagement';
import { useGlobalMyInfo } from '../apartment/myInfo/useGlobalMyInfo';
import { BlurComponent } from '../apartment/myInfo/BlurComponent';
import { BarComponent } from '../apartment/flow/Bar';
extend(isBetween);

export default function TransactionStat() {
	const { globalManagement } = useGlobalManagementStore();
	const [stateParams] = useGlobalMyInfo();
	const isShowLabel =
		stateParams?.globalSensitiveInformationHiding === false ?? true;

	if (!globalManagement) {
		return <div>401</div>;
	}

	const [form] = Form.useForm();
	const formTime = Form.useWatch('time', form);
	useTitle('销售统计');

	const query = useQuery({
		queryKey: ['transaction', formTime],
		queryFn: async () => {
			const res = await honoClient.transaction.$post({
				json: {
					startTime: formTime && startOfMonth(formTime)?.valueOf(),
					endTime: formTime && endOfMonth(formTime)?.valueOf(),
				},
			});
			return await res.json();
		},
	});

	const DataWithSellDate = query.data?.data
		?.map((i) => {
			i.sellDate = dayjs(i.createdAt).format('YYYY-MM-DD');
			return i;
		})
		.sort((a, b) => {
			return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
		});

	const metaData = Object.entries(groupBy(DataWithSellDate, 'sellDate'));

	const groupDateOnlyStockRows = Object.entries(
		groupBy(
			metaData
				.flatMap((a) =>
					a[1].map((b) =>
						b.stockRows.map((c) => ({
							...c,
							sellDate: a[0],
							spec: c?.specifications?.split(' ')?.[0],
						})),
					),
				)
				.flat(),
			(i) => {
				return `${i.sellDate}:${i.spec}`;
			},
		),
	).map((i) => {
		const [sellDate, spec] = i[0].split(':');
		const cubic = roundFix2(
			i[1].reduce((p, c) => {
				return p + Number.parseFloat(c.totalCubic);
			}, 0),
		);

		return {
			sellDate: sellDate,
			spec: spec,
			cubic: cubic,
		};
	});

	const annotations = metaData
		.map((v) => {
			return {
				sellDate: v[0],
				totalCubic: roundFix2(
					v[1].reduce((p, c) => {
						return Number.parseFloat(c.totalCubic) + p;
					}, 0),
				),
			};
		})
		.map((a) => {
			return {
				type: 'text',
				position: [a.sellDate, a.totalCubic],
				content: `${a.totalCubic}`,
				style: { textAlign: 'center', fontSize: 14, fill: 'rgba(0,0,0,1)' },
				offsetY: -20,
			};
		});

	// console.log(`groupDateOnlyStockRows`, groupDateOnlyStockRows);

	const sellPieData = Object.entries(
		groupBy(
			DataWithSellDate?.flatMap((a) =>
				a?.stockRows.map((c) => ({
					...c,
					sellDate: a.sellDate,
					spec: c?.specifications?.split(' ')?.[0],
				})),
			)?.flat(),
			'spec',
		),
	).map((i) => {
		return {
			spec: i[0],
			total: roundFix2(
				i[1].reduce((p, c) => {
					return c.totalCubic + p;
				}, 0),
			),
		};
	});

	const sellPieBySpecialSpecificationsData = Object.entries(
		groupBy(
			DataWithSellDate?.flatMap((a) =>
				a?.stockRows.map((c) => ({
					...c,
					sellDate: a.sellDate,
					spec: c?.specifications,
				})),
			)?.flat(),
			'spec',
		),
	).map((i) => {
		return {
			spec: i[0],
			total: roundFix2(
				i[1].reduce((p, c) => {
					return c.totalCubic + p;
				}, 0),
			),
		};
	});

	const sellClientData = Object.entries(
		groupBy(DataWithSellDate, 'client'),
	).map((i) => {
		return {
			client: i[0],
			totalCubic: roundFix2(
				i[1].reduce((p, c) => {
					return c.totalCubic + p;
				}, 0),
			),
			totalPrice: roundFix2(
				i[1].reduce((p, c) => {
					return c.totalPrice + p;
				}, 0),
			),
		};
	});

	const sellClientCubicData = [...sellClientData].sort(
		(a, b) => b.totalCubic - a.totalCubic,
	);
	const sellClientPriceData = [...sellClientData].sort(
		(a, b) => b.totalPrice - a.totalPrice,
	);

	const allCubic =
		DataWithSellDate?.reduce((p, c) => {
			return p + c.totalCubic;
		}, 0) ?? 0;

	const allPrice =
		DataWithSellDate?.reduce((p, c) => {
			return p + c.totalPrice;
		}, 0) ?? 0;

	const allCost =
		DataWithSellDate?.reduce((p, c) => {
			return p + (isString(c.totalCost) ? 0 : c.totalCost);
		}, 0) ?? 0;

	return (
		<Card>
			<Form
				className="mb-3 gap-2"
				form={form}
				onFinish={(values) => { }}
				initialValues={{
					time: dayjs(stateParams?.time),
				}}
			>
				<MonthTimePicker />
			</Form>

			<div className="flex flex-row gap-3 my-3 flex-wrap">
				<div>总立方数</div>
				<BlurComponent>
					<div>{roundFix4(allCubic)}</div>
				</BlurComponent>
				<div>总金额</div>
				<BlurComponent>
					<div>{roundFix2(allPrice)}</div>
				</BlurComponent>
				<div>总利润(仅统计有成本的成交单)</div>
				<BlurComponent>
					<div>{roundFix2(allPrice - allCost)}</div>
				</BlurComponent>
			</div>

			<div className='grid md:grid-cols-1'>
				{annotations.length > 0 && (
					<div>
						<h3>销量统计 - 立方数</h3>

						<ColumnComponent
							height={380}
							{...{
								xField: 'sellDate',
								yField: 'cubic',
								seriesField: 'spec',
								data: groupDateOnlyStockRows,
								isStack: true,
								label: {
									// 可手动配置 label 数据标签位置
									position: 'middle', // 'top', 'bottom', 'middle'
									// 可配置附加的布局方法
									layout: [
										// 柱形图数据标签位置自动调整
										{ type: 'interval-adjust-position' },
										// 数据标签防遮挡
										{ type: 'interval-hide-overlap' },
										// 数据标签文颜色自动调整
										{ type: 'adjust-color' },
									],
								},
								annotations: annotations,
							}}
						/>
					</div>
				)}

				{sellPieData.length > 0 && (
					<>
						<div className="w-full">
							<h3>销量统计 - 立方数 - 规格</h3>

							<PlotContainer
								height={380}
								{...{
									data: sellPieData,

									angleField: 'total',
									colorField: 'spec',
									radius: 0.75,
									appendPadding: 10,
									label: {
										type: 'spider',
										labelHeight: 28,

										style: {
											fill: '#000000',
										},
										content: '{name}\n{percentage}',
									},
									interactions: [
										{ type: 'element-selected' },
										{ type: 'element-active' },
									],
								}}
							/>
						</div>
						<div className="w-full">
							<h3>销量统计 - 立方数 - 细分规格</h3>

							<PlotContainer
								height={380}
								{...{
									data: sellPieBySpecialSpecificationsData,

									angleField: 'total',
									colorField: 'spec',
									radius: 0.75,
									appendPadding: 10,
									label: {
										type: 'spider',
										labelHeight: 28,

										style: {
											fill: '#000000',
										},
										content: '{name}\n{percentage}',
									},
									interactions: [
										{ type: 'element-selected' },
										{ type: 'element-active' },
									],
								}}
							/>
						</div>
					</>
				)}

				{sellClientCubicData.length > 0 && (
					<div>
						<h3>客户统计 - 立方数</h3>

						<BarComponent
							height={380}
							{...{
								xField: 'totalCubic',
								yField: 'client',
								data: sellClientCubicData,

								xAxis: {
									label: {
										autoRotate: false,
									},
								},
								scrollbar: {
									type: 'horizontal',
								},
							}}
						/>
					</div>
				)}
				{sellClientPriceData.length > 0 && (
					<div>
						<h3>客户统计 - 金额</h3>

						<BarComponent
							height={380}
							{...{
								xAxis: {
									label: {
										autoRotate: false,
										autoHide: false,
										autoEllipsis: false
									},
								},
								xField: 'totalPrice',
								yField: 'client',
								data: sellClientPriceData,
								scrollbar: {
									type: 'horizontal',
								},
							}}
						/>
					</div>
				)}

				<div>
					<h3>总利润</h3>
					<LineComponent
						height={380}
						{...{
							xField: 'sellDate',
							yField: 'totalProfit',
							data: metaData.map((v) => {
								return {
									sellDate: v[0],
									totalProfit: roundFix2(
										v[1].reduce((p, c) => {
											return Number.parseFloat(c.totalProfit) + p;
										}, 0),
									),
								};
							}),
						}}
					/>
				</div>

			</div>
			<Table
				className="my-5"
				rowKey={'uuid'}
				columns={[
					{
						dataIndex: 'orderId',
						title: 'orderId',
					},
					{
						dataIndex: 'totalProfit',
						title: '利润',
					},
					{
						dataIndex: 'totalPrice',
						title: '价格',
					},
					{
						dataIndex: 'totalCubic',
						title: '立方数',
					},
					{
						dataIndex: 'uuid',
						title: 'uuid',
					},
				]}
				dataSource={query.data?.data}
				pagination={{ ...globalAntdPagination, total: query.data?.total ?? 0 }}
			/>
		</Card>
	);
}
